import React from "react";
import Page from "components/Page";
import Header from "components/Header";
import Footer from "components/Footer";
import ExternalLink from "../components/ExternalLink";

const Contact = () => (
	<>
		<Page
			minHeight="90vh"
			middle={(
				<>
					<h1>We help businesses add value, so let&apos;s create something great together!</h1>
					<p>
						If you have a project or a brief you’d like to
						discuss, we will be delighted to hear from you.
					</p>
					<h3><ExternalLink to="mailto:info@wearemothership.com">info@wearemothership.com</ExternalLink></h3>
					<h3><ExternalLink to="tel:+44-1252-622800">UK (44) 1252 622800</ExternalLink></h3>
				</>
			)}
			bottom={(
				<small>
					<b>Salisbury</b>
					,
					<b>Fleet</b>
					{" "}
					and
					{" "}
					<b>Barcelona</b>
				</small>
			)}
		/>
		<Footer />
		<Header />
	</>
);

export default Contact;
